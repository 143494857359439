<template>
  <v-skeleton-loader v-if="loading"
                     type="article"
                     height="300"/>
  <content-card v-else-if="Object.values(services)?.length > 0"
                :title="$t('services-card.title').toString()"
                icon="move_down">
    <template v-slot:content>
      <v-list subheader class="py-0">
        <v-list-item v-for="service in services"
                     :key="service.id"
                     class="list-item straight px-5">
          <v-list-item-title class="font-size-02 primary--text font-weight-bold"
                             v-text="service.name"/>
          <v-list-item-action>
            <v-btn small icon
                   @click="deleteService(service)">
              <v-icon>delete</v-icon>
            </v-btn>
            <v-switch v-model="service.enabled"
                      inset hide-details
                      :loading="loadingSwitch[service.id]"
                      @change="switchEnabled(service)"/>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
  </content-card>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard"
import config from "@/config/config.app.json";
import requestHelper from "@/scripts/requestHelper";

export default {
  name: "ServicesCard",

  components: {ContentCard},

  data() {
    return {
      timer: null,
      refreshRate: config.dataRefreshRate,
      loading: false,
      loadingSwitch: {},
      services: []
    }
  },

  methods: {
    /**
     * gets services from the API
     */
    getData(showLoader) {
      if (showLoader) {
        this.loading = true
      }

      this.$rhRequest.sendGet({
        endpoint: 'service'
      }, (response) => {
        this.services = response?.data?.data
        if(!Object.values(this.services)?.length > 0) {
          this.$emit('hide-services-card', true)
        } else {
          this.$emit('hide-services-card', false)
        }
        this.loading = false
      }, (error) => {
        console.error(error)
        this.$emit('hide-services-card', true)
        this.loading = false
      })
    },

    /**
     * en-/disables a service
     * @param service
     */
    switchEnabled(service) {
      this.$set(this.loadingSwitch, service.id, true)
      this.$rhRequest.sendPost({
        endpoint: 'service/' + service.id + "/update",
        data: service
      }, (response) => {
        this.$set(this.loadingSwitch, service.id, false)
        if (response.data.code === -1) {
          this.$root.bisatoast.error({
            message: this.$t('app.generic-error'),
            showCloseBtn: true
          })
        }
      }, (error) => {
        console.error(error)
        this.$root.bisatoast.error({
          message: this.$t("app.generic-error"),
          showCloseBtn: true
        })
        this.$set(this.loadingSwitch, service.id, false)
      })
    },

    /**
     * continuously updates the data
     */
    updateData() {
      this.timer = setInterval(() => {
        this.getData(false)
      }, this.refreshRate)
    },

    /**
     * Deletes service
     * @param service
     */
    deleteService(service){
      if(!this.loading){
        this.$root.bisadialog.toggle('confirmation', true, {
          maxWidth: 400,
          title: this.$t('confirmation-dialog.title'),
          text: this.$t('rules-card.delete-rule.confirm', {name: service.name}),
          confirmLabel: this.$t('rules-card.delete-rule.button')
        })
        this.$root.$on('dialogConfirmed', () => {
          this.$rhRequest.sendDelete({
            endpoint: 'service/delete/' + service.id
          }, () => {
            this.$root.bisatoast.success({
              message: this.$t('rules-card.delete-rule.success', {name: service.name}),
              showCloseBtn: true
            })
            this.getData(false)
          }, (error) => {
            console.error(error)
            this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
          })
          this.$root.$off('dialogConfirmed')
        })
      }
    },
    loadCachedData() {
      this.$caching.getData('services').then((services) => {
        if (services !== -1) {
          this.services = services
          this.loading = false
          if(!Object.values(this.services)?.length > 0) {
            this.$emit('hide-services-card', true)
          } else {
            this.$emit('hide-services-card', false)
          }
        }
      })
    }
  },

  mounted() {
    this.loading = true
    this.loadCachedData()
    requestHelper.startDelayedRequest(
        () => this.getData(false),
        () => this.updateData()
    )
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },
  watch: {
    services(newVal) {
      this.$caching.saveData('services', newVal)
    }
  }
}
</script>
