<template>
  <div>
    <v-skeleton-loader v-if="loadingLockDevices || loadingWilkaDevices"
                       type="card"
                       class="mb-5"
                       height="160"/>

    <content-card v-else
                  :title="$t('accesses-card.title').toString()"
                  icon="key">

      <template v-slot:content>
        <v-card-text v-if="hasAccessDevices"
                     class="pa-0">
          <v-list subheader class="py-0 tileBackground">
            <v-list-item-group>

              <!-- Nuki Boxes -->
              <div v-for="nukiBox in nukiBoxes"
                   v-bind:key="nukiBox.boxId"
                   class="access-device">
                <nuki-box :device="nukiBox"/>
              </div>

              <!-- Wilka locks -->
              <div v-for="wilkaDevice in wilkaDevices"
                   v-bind:key="wilkaDevice.id"
                   class="access-device">
                <v-list-item inactive
                             :disabled="wilkaDevice.currentlyEnabled !== false"
                             :ripple="false"
                             class="px-0 mb-4">
                  <v-list-item-icon class="ml-0 mr-3">
                    <v-icon :color="wilkaDevice.currentlyEnabled !== false ? 'primary' : ''">door_front</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="font-size-03"
                                       :class="wilkaDevice.currentlyEnabled !== false ? 'primary--text font-weight-bold' : ''"
                                       v-text="wilkaDevice?.attributes?.name"></v-list-item-title>
                    <v-list-item-subtitle v-if="wilkaDevice.currentlyEnabled === false"
                                          class="font-size-02"
                                          v-html=" $t('app.additional-permission.no-permission')"/>
                    <v-list-item-subtitle v-else-if="isWilkaDeviceActuated(wilkaDevice.attributes.bluetoothId)"
                                          class="font-size-02"
                                          v-html="$t('accesses-card.device-status.actuated')"/>
                    <v-list-item-subtitle v-else-if="isWilkaDeviceActive(wilkaDevice.attributes.bluetoothId)"
                                          class="font-size-02"
                                          v-html="$t('accesses-card.device-status.reachable')"/>
                    <v-list-item-subtitle v-else-if="!isBluetoothEnabled"
                                          class="font-size-02"
                                          v-html="$t('accesses-card.device-status.bluetooth-inactive')"/>
                    <v-list-item-subtitle v-else
                                          class="font-size-02">
                      {{ $t('accesses-card.device-status.not-in-range') }}
                      <v-progress-circular indeterminate
                                           size="10" width="2"
                                           class="ml-1"/>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-card-actions class="pr-0">
                    <v-icon v-if="!isBluetoothEnabled" :color="wilkaDevice.currentlyEnabled !== false ? 'error' : ''">
                      bluetooth_disabled
                    </v-icon>
                    <v-icon v-if="wilkaDevice.attributes.batteryLevel === 0"
                            :color="wilkaDevice.currentlyEnabled !== false ? 'error' : ''">battery_alert
                    </v-icon>
                    <v-icon v-else-if="wilkaDevice.attributes.batteryLevel === 1"
                            :color="wilkaDevice.currentlyEnabled !== false ?'warning' : ''">battery_2_bar
                    </v-icon>
                    <v-icon v-else-if="wilkaDevice.attributes.batteryLevel === 5"
                            :color="wilkaDevice.currentlyEnabled !== false ? 'primary' : ''">battery_full
                    </v-icon>
                    <v-icon v-else-if="wilkaDevice.attributes.batteryLevel > 0"
                            :color="wilkaDevice.currentlyEnabled !== false ? 'primary' : ''">
                      {{ "battery_" + (wilkaDevice.attributes.batteryLevel + 2) + "_bar" }}
                    </v-icon>
                  </v-card-actions>
                </v-list-item>

                <v-btn depressed block large
                       class="font-weight-bold"
                       color="primary"
                       :disabled="wilkaDevice.currentlyEnabled === false || !isWilkaDeviceActive(wilkaDevice.attributes.bluetoothId)"
                       @click="actuateWilka(wilkaDevice)"
                       v-html="$t('accesses-card.execute-button')"/>
              </div>

              <!-- Nuki & Kaadas Locks -->
              <div v-for="lockDevice in lockDevices"
                   v-bind:key="lockDevice.id"
                   class="access-device">
                <nuki-lock :device="lockDevice" v-if="lockDevice?.type === DeviceTypes.NUKI_LOCK"/>
                <nuki-opener :device="lockDevice" v-else-if="lockDevice?.type === DeviceTypes.NUKI_OPENER"/>
                <kaadas-lock :device="lockDevice" v-else-if="lockDevice?.type === DeviceTypes.KAADAS_LOCK"/>
              </div>

            </v-list-item-group>
          </v-list>
        </v-card-text>

        <!-- empty state -->
        <v-card-text v-else
                     class="pa-5">
          <span v-html="$t('accesses-card.empty-state', {support: emptyStateLink})"/>
          <div class="pt-5">
            <v-btn plain block
                   color="primary"
                   class="font-weight-bold"
                   to="support">
              {{ $t('accesses-card.empty-state.button') }}
            </v-btn>
          </div>
        </v-card-text>
      </template>
    </content-card>
  </div>
</template>

<script>
import config from "@/config/config.app.json";
import ContentCard from "@/templates/components/ContentCard";
import NukiLock from "@/templates/components/devices/access/NukiLock";
import NukiBox from "@/templates/components/devices/access/NukiBox";
import NukiOpener from "@/templates/components/devices/access/NukiOpener";
import KaadasLock from "@/templates/components/devices/access/KaadasLock";
import requestHelper from "@/scripts/requestHelper";

export const DeviceTypes = Object.freeze({
  NUKI_BOX: "nuki-box",
  NUKI_OPENER: "nuki-opener",
  NUKI_LOCK: "nuki-lock",
  BURG_WAECHTER: "switch",
  KAADAS_LOCK: "kaadas-lock"
});

export default {
  name: "AccessesCard",
  components: {NukiBox, NukiLock, NukiOpener, ContentCard, KaadasLock},

  data() {
    return {
      timer: null,
      // Nuki & Kaadas
      loadingLockDevices: false,
      nukiBoxes: [],
      lockDevices: [],
      DeviceTypes: DeviceTypes,
      // WILKA
      simulatedStatusDuration: 5000,
      discoverTimeout: 5000,
      isBluetoothEnabled: this.$bluetoothDeviceManager.bluetoothEnabled,
      loadingWilkaDevices: false,
      wilkaDevices: [],
      activeWilkaDevices: {},
      wilkaMacsCurrentlyActuating: [],
    }
  },

  methods: {
    getLockDevices(showLoader) {
      if (showLoader) {
        this.loadingLockDevices = true
      }
      this.$rhRequest.sendGet({
        endpoint: "devices/get-lock-devices",
      }, (response) => {
        this.nukiBoxes = response?.data?.data?.nukiBoxes
        this.lockDevices = response?.data?.data?.lockDevices
        this.loadingLockDevices = false

      }, (err) => {
        console.error(err)
        this.loadingLockDevices = false
      })
    },

    /**
     * continuously updates the nuki device data
     */
    updateData() {
      this.timer = setInterval(() => {
        this.getLockDevices(false)
        this.getWilkaDevices(false)
      }, config.updateInterval)
    },

    getWilkaDevices() {
      let self = this
      this.$bluetoothDeviceManager.addEventListener('known.devices', (devices, batteryLevels) => {
        self.wilkaDevices = []
        if (devices.length > 0) {
          devices.forEach((device, i) => {
            if (device?.type === 'lockCylinders' && batteryLevels) {
              batteryLevels.forEach((batteryLevel) => {
                if (device?.relationships?.batteryLevel?.data?.id === batteryLevel.id) {
                  devices[i].attributes.batteryLevel = batteryLevel.attributes.value
                }
              })
            }
          })
        }
        self.wilkaDevices = devices
        self.loadingWilkaDevices = false
      })

      setTimeout(function () {
        self.$bluetoothDeviceManager.discover()
        self.loadingWilkaDevices = false
      }, this.discoverTimeout);
      this.$bluetoothDeviceManager.update()
    },

    isWilkaDeviceActive(mac) {
      return this.activeWilkaDevices[mac]
    },

    isWilkaDeviceActuated(mac) {
      return this.wilkaMacsCurrentlyActuating.includes(mac)
    },

    actuateWilka(wilkaDevice) {
      let mac = wilkaDevice.attributes.bluetoothId

      if (!this.isWilkaDeviceActive(mac)) {
        this.$root.bisatoast.error({message: this.$t('accesses-card.actuate-wilka.not-in-range')})
        return
      }

      // open with cooldown
      let self = this
      if (this.wilkaMacsCurrentlyActuating.includes(mac)) {
        return
      } else {
        this.wilkaMacsCurrentlyActuating.push(mac)
        setTimeout(function () {
          self.wilkaMacsCurrentlyActuating = self.wilkaMacsCurrentlyActuating.filter(v => v !== mac);
        }, this.simulatedStatusDuration)
      }

      this.$bluetoothDeviceManager.openLock(mac)
    },

    wilkaDeviceConnected(mac) {
      if (this.activeWilkaDevices[mac] !== true)
        this.$set(this.activeWilkaDevices, mac, true)
    },

    wilkaDeviceDisconnected(mac) {
      if (this.activeWilkaDevices[mac] !== false)
        this.$set(this.activeWilkaDevices, mac, false)
    },

    onWilkaOpenSuccess() {
      this.$root.bisatoast.success({message: this.$t('accesses-card.activate-wilka.success')})
    },

    onWilkaOpenError() {
      this.$root.bisatoast.error({message: this.$t('accesses-card.activate-wilka.error')})
    },

    onBluetoothDisabled() {
      this.isBluetoothEnabled = false
    },

    onBluetoothEnabled() {
      this.isBluetoothEnabled = true
    },
    loadCachedData() {

      this.$caching.getData('nukiBoxes').then(nukiBoxes => {
        if (nukiBoxes !== -1) {
          this.nukiBoxes = nukiBoxes
          this.loadingLockDevices = false
        }
      })
      this.$caching.getData('lockDevices').then(lockDevices => {
        if (lockDevices !== -1) {
          this.lockDevices = lockDevices
          this.loadingLockDevices = false
        }
      })
      this.$caching.getData('smartLockDevices').then(smartLockDevices => {
        if (smartLockDevices !== -1) {
          this.smartLockDevices = smartLockDevices
          this.loadingWilkaDevices = false
        }
      })
      this.$caching.getData('activeWilkaDevices').then(activeWilkaDevices => {
        if (activeWilkaDevices !== -1) {
          this.activeWilkaDevices = activeWilkaDevices
        }
      })
    }
  },

  computed: {
    emptyStateLink() {
      return "<a href=\"#/support\" class=\"primary--text text-decoration-none font-weight-bold\">"
          + this.$t('support-page.title')
          + "</a>"
    },

    hasAccessDevices() {
      let notEmpty = this.nukiBoxes?.length > 0 || this.wilkaDevices?.length > 0 || this.lockDevices?.length > 0
      if (!notEmpty && config.hideAccessesCardWhenEmpty === 'true') {
        this.$emit('hide-accesses', true)
      } else {
        this.$emit('hide-accesses', false)
      }
      return notEmpty
    }
  },

  mounted() {
    this.loadingLockDevices = true
    this.loadingWilkaDevices = true
    this.loadingNukiDevices = true
    this.loadCachedData()
    requestHelper.startDelayedRequest(
        () => this.getLockDevices(false),
        () => this.updateData()
    )
    requestHelper.startDelayedRequest(
        () => this.getWilkaDevices()
    )

    let self = this
    this.wilkaUpdateTimer = setTimeout(function () {
      self.$bluetoothDeviceManager.update()
    }, config.dataRefreshRate);

    // Wilka event listeners
    this.$bluetoothDeviceManager.addEventListener('wilka.connected', this.wilkaDeviceConnected.bind(this))
    this.$bluetoothDeviceManager.addEventListener('wilka.disconnected', this.wilkaDeviceDisconnected.bind(this))
    this.$bluetoothDeviceManager.addEventListener('wilka.open.success', this.onWilkaOpenSuccess.bind(this))
    this.$bluetoothDeviceManager.addEventListener('wilka.open.error', this.onWilkaOpenError.bind(this))
    this.$bluetoothDeviceManager.addEventListener('bluetooth.disabled', this.onBluetoothDisabled.bind(this))
    this.$bluetoothDeviceManager.addEventListener('bluetooth.enabled', this.onBluetoothEnabled.bind(this))
  },

  beforeDestroy() {
    clearTimeout(this.wilkaUpdateTimer)
    clearInterval(this.timer)
    this.$bluetoothDeviceManager.removeEventListeners('known.devices')
    this.$bluetoothDeviceManager.removeEventListeners('wilka.connected')
    this.$bluetoothDeviceManager.removeEventListeners('wilka.disconnected')
    this.$bluetoothDeviceManager.removeEventListeners('wilka.open.success')
    this.$bluetoothDeviceManager.removeEventListeners('wilka.open.error')
    this.$bluetoothDeviceManager.removeEventListeners('bluetooth.disabled')
    this.$bluetoothDeviceManager.removeEventListeners('bluetooth.enabled')
  },
  watch: {
    nukiBoxes(newVal) {
      this.$caching.saveData('nukiBoxes', newVal)
    },
    lockDevices(newVal) {
      this.$caching.saveData('lockDevices', newVal)
    },
    smartLockDevices(newVal) {
      this.$caching.saveData('smartLockDevices', newVal)
    },
    activeWilkaDevices(newVal) {
      this.$caching.saveData('activeWilkaDevices', newVal)
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/living/accesses-card.scss';
</style>

