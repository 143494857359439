export default class IndexedDBCache {
    constructor(dbName) {
        this.dbName = dbName
        this.storeName = localStorage.getItem('activeSite')
        this.version = 1
        this.db = null
        this.openingDB = null
        this.initDB()
    }

    /**
     * Initialize IndexedDB database
     *
     * @async
     * @returns {Promise}
     */
    async initDB() {
        if (this.openingDB) {
            await this.openingDB
            if (this.db && this.db.version === this.version) {
                return
            }
        }
        let promise = new Promise((resolve, reject) => {
            if (!window.indexedDB) {
                reject("IndexedDB not supported")
                return
            }
            if (this.db) {
                this.db.close()
                this.db = null
            }

            this.storeName = localStorage.getItem('activeSite')
            let request
            if (this.version === 1) {
                request = window.indexedDB.open(this.dbName)
            } else {
                request = window.indexedDB.open(this.dbName, this.version)
            }

            request.onerror = (event) => {
                reject(event.target.error)
            }

            request.onsuccess = (event) => {
                this.db = event.target.result
                resolve(event)
            }

            request.onupgradeneeded = (event) => {
                const db = event.target.result
                db.createObjectStore(this.storeName, {keyPath: "id"})
                resolve(event)
            }

            request.onblocked = () => {
                console.error("Unable to access IndexedDB due to a database being locked by another process.")
                reject("Database locked")
            }
        })
        this.openingDB = promise
        return promise
    }

    /**
     * Save data to IndexedDB
     *
     * @async
     * @param {number} id - Unique identifier for the data
     * @param {any} data - Data to be saved
     * @returns {Promise}
     */
    async saveData(id, data) {
        if (this.openingDB && this.openingDB.status === "pending") {
            await this.openingDB
        }
        if (!this.db) {
            await this.initDB()
        }

        this.storeName = localStorage.getItem('activeSite')
        if (!this.db.objectStoreNames.contains(this.storeName)) {
            this.version = this.db.version
            this.version++
            await this.initDB()
        }

        const transaction = this.db.transaction([this.storeName], "readwrite")
        const objectStore = transaction.objectStore(this.storeName)
        const request = objectStore.put({id, data})

        request.onerror = (event) => {
            console.error("Error saving data:", event.target.error)
        }
    }
    /**
     * Retrieve data from IndexedDB
     *
     * @async
     * @param {number} id - Unique identifier for the data
     * @returns {Promise} - The data associated with the given id, or -1 if no data exists for the given id
     */
    async getData(id) {
        if (this.openingDB) {
            await this.openingDB
        }
        if (!this.db) {
            await this.initDB()
        }
        if (!this.db.objectStoreNames.contains(this.storeName)) {
            this.version = this.db.version
            this.version++
            await this.initDB()
        }
        try {
            const transaction = this.db.transaction([this.storeName], "readonly")
            const objectStore = transaction.objectStore(this.storeName)

            const request = objectStore.get(id)

            return new Promise((resolve, reject) => {
                request.onsuccess = () => {
                    if (!request.result) {
                        this.saveData(id, null)
                        resolve(-1)
                        return
                    }
                    resolve(request.result.data)
                }

                request.onerror = (event) => {
                    console.error("Error retrieving data:", id, event.target.error)
                    reject(event.target.error)
                }
            })
        } catch (error) {
            console.error("Error retrieving data:", id, error)
            return null
        }
    }
    /**
     * Delete the database
     */
    deleteDatabase() {
        // Delete complete database
        this.db.close()
        window.indexedDB.deleteDatabase(this.dbName)
    }
}