<!--
  This template gets the gateway status from the API and shows a v-card with the status (online, offline)
-->
<template>
  <v-card class="pa-5">
    <v-list-item class="px-0">
      <v-list-item-icon>
        <v-icon :color="statusColor" large>
          podcasts
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="font-weight-bold">
          <v-skeleton-loader v-if="loading" type="heading"/>
          <span v-else-if="online" v-html="$t('gateway-status-card.online')"/>
          <span v-else-if="timeout" v-html="$t('gateway-status-card.timeout')"/>
          <span v-else v-html="$t('gateway-status-card.offline')"/>
        </v-list-item-title>
        <v-list-item-subtitle v-if="!loading && !!mac"
                              v-html="$t('gateway-status-card.mac', {mac: mac})"/>
      </v-list-item-content>
      <v-list-item-action v-if="!loading && !online"
                          class="d-none d-md-block">
        <v-btn outlined
               color="primary"
               @click="showHelp">
          <v-icon left class="non-flip">help</v-icon>
          {{ $t('gateway-status-card.help-btn') }}
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-list-item v-if="!loading && !online"
                 class="px-0 d-md-none">
      <v-list-item-content class="pb-0">
        <v-btn outlined
               color="primary"
               @click="showHelp">
          <v-icon left class="non-flip">help</v-icon>
          {{ $t('gateway-status-card.help-btn') }}
        </v-btn>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
import config from "@/config/config.app.json";
import requestHelper from "@/scripts/requestHelper";

export default {
  name: "GatewayStatusCard",

  data: () => ({
    loading: false,
    online: null,
    timeout: false,
    mac: ''
  }),

  methods: {
    /**
     * gets gateway data for the currently active site
     */
    getGateway(showLoader) {
      if (showLoader) {
        this.loading = true
      }
      this.$rhRequest.sendGet({
        endpoint: 'gateway'
      }, (response) => {
        this.timeout = false
        this.online = response?.data?.data[0]?.online
        this.mac = response?.data?.data[0]?.model
        this.$emit('mac', this.mac)
        this.loading = false
      }, (error) => {
        if (error.code === 'ECONNABORTED') {
          this.timeout = true
        }
        console.error(error)
        this.loading = false
      })
    },

    /**
     * continuously updates the data
     */
    updateData() {
      this.timer = setInterval(() => {
        this.getGateway(false)
      }, config.updateInterval)
    },

    /**
     * opens a help dialog
     */
    showHelp() {
      this.$root.bisadialog.toggle('gatewayOfflineHelp')
    },
    loadCachedData() {
      Promise.all([
        this.$caching.getData('mac').then((mac) => {
          if (mac !== -1) {
            this.mac = mac
          }
        }),
          this.$caching.getData('gatewayOnline').then((online) => {
            if (online!== -1) {
              this.online = online
            }
          })
      ]).then(() => {
        this.loading = false
      })

    }
  },

  computed: {
    statusColor() {
      if (this.online) {
        return 'success'
      } else if (this.loading || this.timeout) {
        return ''
      } else {
        return 'error'
      }
    }
  },

  mounted() {
    this.loading = true
    this.loadCachedData()
    requestHelper.startDelayedRequest(
        () => this.getGateway(false),
        () => this.updateData()
    )
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },
  watch: {
    mac(newVal) {
      this.$caching.saveData('mac', newVal)
    },
    online(newVal) {
      this.$caching.saveData('gatewayOnline', newVal)
    }
  }
}
</script>
