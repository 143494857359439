<template>
  <div id="home">
    <!-- page header -->
    <v-img height="200"
           :src="headerImgSrc">
      <div class="header-wrapper">
        <div class="pl-6 pt-9 header white--text">
          <div class="header-icon pointer"
               @click="changeSite">
            <v-icon color="primary">
              home
            </v-icon>
          </div>
          <div class="header-text d-inline-block">
            <v-skeleton-loader v-if="loading.site"
                               max-width="300"
                               class="mb-2"
                               type="heading"/>
            <div v-else class="font-weight-bold font-size-07">
              <v-text-field v-if="editSiteName"
                            v-model="siteName"
                            class="mb-2"
                            ref="site-name-input"
                            rounded hide-details dense>
                <template v-slot:append>
                  <v-icon color="primary"
                          @click="updateSiteName">
                    check
                  </v-icon>
                </template>
              </v-text-field>
              <div v-else
                   class="ml-4 pointer"
                   @click="changeSite">
                {{ siteName }}
                <!-- Disabled: Design review neccessary -->
                <!--<span @click="changeSiteName">{{ siteName }}</span>
                <v-btn icon
                       @click="changeSiteName">
                  <v-icon small color="white">edit</v-icon>
                </v-btn>-->
              </div>
            </div>
            <v-skeleton-loader v-if="loading.site"
                               max-width="300"
                               type="text"/>
          </div>
          <v-icon v-if="!loading.site" @click="changeSite" color="white" class="pt-1 material-icons-outlined">expand_more</v-icon>
        </div>
      </div>
    </v-img>

    <!-- content -->
    <div class="page-content">

      <v-row>
        <!-- timelines section -->
        <v-col cols="12" sm="6" lg="4"
               v-if="hasTimelines"
                v-show="!hideTimelines">
          <timelines-card @hide-timelines="(value) => hideTimelines = value"
                          ref="timelinesCard"/>
        </v-col>

        <!-- offers (marketplace) items -->
        <v-col cols="12" sm="6" lg="4"
               v-if="!hideOffers">
          <offers-carousel @hide-offers="(value) => hideOffers = value"
                           ref="offersCard"/>
        </v-col>

        <!-- accesses section -->
        <v-col cols="12" sm="6" lg="4"
               v-show="!hideAccesses"
               v-if="hasAccessesCard">
          <accesses-card @hide-accesses="(value) => hideAccesses = value"
                         ref="accessesCard"/>
        </v-col>

        <!-- chargingstation section -->
        <v-col cols="12" sm="6" lg="4"
               v-if="hasChargingStations"
               v-show="!hideChargingstations">
          <chargingstation-home-card @hide-chargingstations="(value) => hideChargingstations = value"
                                     ref="chargingStationsCard"/>
        </v-col>

        <!-- use case card: room climate -->
        <v-col cols="12" sm="6" lg="4"
               v-if="gatewayAdded && hasSmarthome"
               v-show="!hideRoomClimate">
          <room-climate-card @hide-room-climate="(value) => hideRoomClimate = value"
                             ref="roomClimateCard"/>
        </v-col>

        <v-col cols="12" sm="6" lg="4"
               v-if="gatewayAdded && hasSmarthome"
               v-show="!hideIRController">
          <i-r-controller-card @hide-ircontroller="(value) => hideIRController = value"
                               ref="irControllerCard"/>
        </v-col>

        <!-- smart home section -->
        <v-col cols="12" sm="6" lg="4"
               v-if="!loading.site && hasSmarthome"
                v-show="!hideFavorites">
          <v-skeleton-loader v-if="loading.site"
                             type="card"/>
          <no-gateway-card v-else-if="!gatewayAdded"/>

          <device-favorites-card v-else-if="!hideFavorites"
                                 @hide-favorites="(value) => hideFavorites = value"
                                 ref="deviceFavoritesCard"/>
        </v-col>

        <!-- feedback section -->
        <v-col cols="12" sm="6" lg="4"
               v-if="!hideFeedbackCard">
          <feedback-card/>
        </v-col>

        <!-- add further sections here with v-col -->

      </v-row>

    </div>
  </div>
</template>

<script>

import config from '@/config/config.app.json'
import AccessesCard from "@/templates/components/living/AccessesCard";
import NoGatewayCard from "@/templates/components/emptyStates/NoGatewayCard";
import FeedbackCard from "@/templates/components/living/FeedbackCard";
import DeviceFavoritesCard from "@/templates/components/living/DeviceFavoritesCard";
import RoomClimateCard from "@/templates/components/living/RoomClimateCard";
import requestHelper from "@/scripts/requestHelper";
import theme from "@/config/theme";
import OffersCarousel from "@/templates/components/living/OffersCarousel.vue";
import TimelinesCard from "@/templates/components/living/TimelinesCard";
import ChargingstationHomeCard from "@/templates/components/living/ChargingstationHomeCard.vue";
import IRControllerCard from "@/templates/components/living/irController/IRControllerCard.vue";

export default {
  name: 'HomePage',

  components: {
    IRControllerCard,
    OffersCarousel,
    TimelinesCard,
    RoomClimateCard,
    DeviceFavoritesCard,
    FeedbackCard,
    NoGatewayCard,
    AccessesCard,
    ChargingstationHomeCard
  },

  props: [
    'bottomNavigation'
  ],

  data: () => ({
    loading: {
      site: false,
      offers: false
    },
    timer: {
      site: null
    },
    siteType: null,
    siteName: '',
    favoriteDevices: [],
    editSiteName: false,
    hideRoomClimate: false,
    hideIRController: false,
    hideFavorites: false,
    hideOffers: false,
    hideTimelines: false,
    hideAccesses: false,
    hideChargingstations: false
  }),

  computed: {
    /**
     * returns true if a gateway was added to the currently active site
     *
     * @returns {boolean}
     */
    gatewayAdded() {
      return this.siteType !== 'no_gateway'
    },

    /**
     * returns true if Smarthome is available for this client
     * @returns {boolean}
     */
    hasSmarthome() {
      return config.hasSmarthome !== 'false'
    },

    /**
     * returns true if the timelines should be visible for this client
     * @returns {boolean}
     */
    hasTimelines() {
      return config.hasTimelines !== 'false'
    },

    /**
     * returns true if the accesses card should be visible for this client
     * @returns {boolean}
     */
    hasAccessesCard() {
      return config.hasAccessesCard !== 'false'
    },

    /**
     * returns true if the chargingstations overview card should be visible for this client
     * @returns {boolean}
     */
    hasChargingStations() {
      return config.hasChargingStations === 'true'
    },

    /**
     * returns true if the home image should have no background-gradient
     * @returns {boolean}
     */
    hasSolidColorHomeHeader() {
      return config.hasSolidColorHomeHeader === 'true'
    },

    /**
     * Allows to override the header logo src in theme.js
     *
     * @returns {*}
     */
    headerImgSrc() {
      return theme.props?.headerLogoSrc
    },

    /**
     * returns true if the feedback card must be hidden
     * @returns {boolean}
     */
    hideFeedbackCard() {
      return config.hideFeedbackCard === 'true'
    }
  },

  methods: {
    getSiteInfo(showError) {

      this.timer.site = setTimeout(() => {
        this.getSiteInfo(false)
      }, config.updateInterval)

      this.$rhRequest.sendGet({
        endpoint: "site/get-general-information",
      }, (resp) => {
        this.loading.site = false
        this.siteType = resp?.data?.data?.siteType
        if (!this.editSiteName) {
          this.siteName = resp?.data?.data?.siteName
        }
        this.$showFirstLaunchDialog({firstName: resp?.data?.data?.firstName})
        if (this.gatewayAdded && !this.hideFavorites) {
          this.$nextTick(() => { // wait until refs are defined
            this.$refs.deviceFavoritesCard?.getFavoriteDevices(showError)
          })
        }
      }, (err) => {
        console.error(err)
        this.loading.site = false
        // don't pass data to the First Launch Dialog because the request failed (and no data was loaded)
        this.$showFirstLaunchDialog()
      })
    },

    changeSiteName() {
      this.editSiteName = true
      this.$nextTick(() => { // wait until refs are defined
        this.$refs['site-name-input'].focus()
      })
    },

    updateSiteName() {
      this.$rhRequest.sendPost({
        endpoint: "site/save-name",
        data: {
          name: this.siteName
        }
      }, () => {
        this.editSiteName = false
      }, (error) => {
        console.error(error)
        this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
      })
    },

    changeSite() {
      this.$root.bisadialog.toggle('changeSite', true)
      this.$root.bisadialog.callDialogInit('changeSite')
      // listen for close on change site dialog to reload site information displayed at top of profile page
      this.$root.$on('close-change-site-dialog', () => {
        // reload data
        this.getSiteInfo(true)
        if (!this.hideRoomClimate) {
          this.$refs.roomClimateCard?.getData(true)
        }
        if (!this.hideIRController) {
          this.$refs.irControllerCard?.getData(true)
        }
        if (!this.hideOffers) {
          this.$refs.offersCard?.getData(true)
        }
        if (!this.hideTimelines) {
          this.$refs.timelinesCard?.getData(true)
        }
        if (!this.hideAccesses) {
          this.$refs.accessesCard?.getNukiDevices(true)
          this.$refs.accessesCard?.getWilkaDevices(true)
        }
        if (!this.hideChargingstations) {
          this.$refs.chargingStationsCard?.getCompleoConnections(true)
        }
        // stop listener
        this.$root.$off('close-change-site-dialog')
      })
    },
  },

  mounted() {
    this.loading.site = true
    requestHelper.startDelayedRequest(
        () => this.getSiteInfo(true)
    )
  },

  beforeDestroy() {
    clearTimeout(this.timer.site)
  }
}
</script>

<style lang="scss">
@import '~@/styles/living/living-section.scss';
@import '~@/styles/living/home.scss';

.pointer {
  cursor: pointer;
}
</style>
